import { getBaseUrl } from '../../utils/utilFunctions';

export const generateBuilderSlug = (siteContact, phoneNumber, jobIds, potentialJobIds = []) => {
  const normalizedPhone = phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`;
  const allJobIds = potentialJobIds.length > 0 ? `${jobIds.join(',')},+${potentialJobIds.join(',')}` : jobIds.join(',');

  const slug = btoa(`${siteContact}${normalizedPhone}+${allJobIds}`);
  const baseUrl = getBaseUrl();
  const link = `${baseUrl}/builders/${slug}`;

  // ToDo (one day): create a System Setting for domain display name
  let domainName = 'Adtech Contracting Pty Ltd';
  if (baseUrl.includes('onewire')) {
    domainName = 'One Wire Pty Ltd\nnbn@onewire.com.au';
  } else if (baseUrl.includes('marshaltech')) {
    domainName = 'Marshal Tech';
  } else if (baseUrl.includes('yourlocalservices')) {
    domainName = 'Your Local Services';
  }

  return { slug, link, domainName };
};

export const generateBuilderMessage = (address, link, domainName) => {
  //return `Dear Builder,\n\nRE: ${address}.\n\nWe are contacting you as the preferred NBN preinstall contractor in the area for your current / upcoming builds.\n\nWhilst we roll out NBN's FTTP network, in order to ensure a smooth, cost free and efficient installation, we kindly request that you ensure site access is available to the property and the garage.\n\nPlease click on the link below to approve site access to the property and to avoid connection delay.\n\nIf you do not wish for the NBN preinstall to be completed, please click on the below link and select the "Deny" option.\n\n${link}\n\nYours sincerely, ${domainName}`;
  return `Dear Builder,\n\nRE: ${address}.\n\nWe are reaching out as a preferred NBN preinstall contractor in your area for your current and upcoming builds.\n\nThe nbn preinstallation is a free service that allows nbn fibre and connection devices to be installed during construction, ensuring the new homeowner can connect immediately while preventing potential damage to driveways or landscaping.\n\nIt is an nbn requirement that pre-install contractors have builders approval for site access before pre-installation can proceed so we kindly ask that you to click on the link below to approve site access.\n${link}\n\nAccess to the garage, is required to complete the installation.\n\nWe appreciate your cooperation and look forward to working with you.\n\nYours sincerely,\n${domainName}`;
};
