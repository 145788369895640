import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { Link, useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import { useAuth } from '../../auth/AuthProvider';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Table, Col, Row, Spinner } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import Sync from './Sync';
import placeholder from '../../assets/img/placeholder.png';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { GET_ACTIVEITEMCHILDCODES } from '../Construction/Schedule';
import { getAddress, formatDate, formatDateTime } from '../../utils/utilFunctions';
import { getFields } from '../SystemSettings/StaticFieldLists';

const CONSTRUCTION_ATTRIBUTES = `
  id
  status
  comments
  workOrder
  releaseDate
  projectFSAM
  description
  address
  contactDetails
  buildType
  workType
  premises
  deliveryPartner
  deliveryPartnerPm
  adtechPm
  scheduler
  originalDueDate
  updatedDueDate
  dbydExpiryDate
  startDueDate
  laseDate
  onsiteDate
  crqDate
  crqNumber
  integrationDate
  acceleratedJob
  siteContact
  siteContactPhone
  customerRef
  bomComments
  contract
  region
  developer
  quoteNumber
  prePaymentNumber
  checklist
  lotNumber
  unitNumber
  streetNumber
  streetName
  roadType
  suburb
  state
  postCode
  boqs (orderBy: { path: "itemType", descending: false }) {
    id
    code
    description
    itemType
    unitOfMeasure
    quantity
    variationQty
  }
  schedules {
    id
    name
    owner
    resource
    groupId
    issuedDate
    dueDate
    completedDate
    submittedDate
    comments
    initial
    ignored
    isPayment
    isReopened
    onsiteDate
    acceptedDate
    declinedDate
    ticketOfWork
    childTaskId
    externalJobLink
    items {
      id
      boqId
      childCode
      quantity
      completedQty
      variationReason
      rejectedDate
      rate
    }
    taskUsers {
      id
      userId
      scheduleId
      onSiteUtc
      offSiteUtc
      completedUtc
      confirmItemQtys
      comments
      user {
        id
        displayName
      }
    }
    fieldComments {
      id
      scheduleId
      userId
      comments
      createdUtc
      user {
        id
        displayName
      }
    }
  }
  payments {
    id
    name
    owner
    paymentItems {
      id
      itemCode
      comments
    }
  }
  attachments (orderBy: { path: "createdUtc", descending: true })
  {
    id
    title
    url
    key
    sizeBytes
    tags
    createdUtc
    modifiedUtc
    comments
  }
`;

export const GET_CONSTRUCTION = gql`
  query ConstructionQuery($constructionId: ID) {
    construction(id: $constructionId) {
      ${CONSTRUCTION_ATTRIBUTES}
    }
    users(role: "Program: Construction") {
      id
      email
      firstName
      lastName
      displayName
    }
    groups {
      id
      name
      isSubcontractor
    }
    addressTypeSetting: systemSettings(where: { path: "Name", comparison: equal, value: "Address Type" }) {
      id
      name
      workType
      config
    }
  }
`;

const GET_ITEMCODES = gql`
  query constructionItemCodes($constructionId: ID!) {
    constructionItemCodes(
      constructionId: $constructionId
      orderBy: [
        { path: "itemType", descending: false }
        { path: "code", descending: false }
        { path: "startDateUtc", descending: true }
      ]
    ) {
      id
      deliveryPartner
      contract
      code
      description
      unitOfMeasure
      region
      unitAmount
      startDateUtc
      program
      itemType
    }
  }
`;

const GET_FIELD_WORK_TASK_SETTINGS = gql`
  query SystemSettingsQuery($workType: [String]) {
    optionalFields: systemSettings(
      where: [
        { path: "Name", comparison: equal, value: "Field Work Task" }
        { path: "WorkType", comparison: equal, value: $workType }
      ]
    ) {
      id
      name
      config
    }
    fieldWorkList: systemSettings(
      where: [
        { path: "Name", comparison: equal, value: "Field Work List" }
        { path: "WorkType", comparison: equal, value: $workType }
      ]
    ) {
      id
      name
      config
    }
    jobStatusSettings: systemSettings(
      where: [
        { path: "Name", comparison: equal, value: "Job Status Settings" }
        { path: "WorkType", comparison: equal, value: $workType }
      ]
    ) {
      id
      name
      config
    }
    customDisplayNames: systemSettings(
      where: [
        { path: "Name", comparison: equal, value: "Optiona Construction Fields" }
        { path: "WorkType", comparison: equal, value: $workType }
      ]
    ) {
      id
      name
      workType
      config
    }
    builders: lookups(where: { path: "Name", comparison: equal, value: "Builder Lookup" }) {
      name
      data
    }
  }
`;

export const UPDATE_CONSTRUCTION = gql`
mutation UpdateConstruction($construction: ConstructionInput!) {
  updateConstruction(construction: $construction, includeChildren: false, includeAttachments: true, includeSchedule: true, updateNullDueDate: true) {
    ${CONSTRUCTION_ATTRIBUTES}
  }
}`;

const ADD_BOQ_AND_ITEM = gql`
mutation AddBoqAndItem($scheduleId: ID!, $taskUserId: ID!, $boq: ConstructionBoqInput!, $item: ConstructionScheduleItemInput!) {
  updateConstructionAddBOQAndItem(scheduleId: $scheduleId, taskUserId: $taskUserId, boq: $boq, item: $item) {
    ${CONSTRUCTION_ATTRIBUTES}
  }
}`;

const UPDATE_SCHEDULE_ITEM_COMPLETION = gql`
mutation UpdateScheduleItemCompletion($item: ConstructionScheduleItemInput!) {
  updateConstructionScheduleItemCompletion(item: $item) {
    ${CONSTRUCTION_ATTRIBUTES}
  }
}
`;

const UPDATE_TASKACCEPTED = gql`
mutation UpdateConstructionTaskAccepted($scheduleId: ID!) {
  updateConstructionTaskAccepted(scheduleId: $scheduleId) {
    ${CONSTRUCTION_ATTRIBUTES}
  }
}`;

const UPDATE_TASKDECLINED = gql`
mutation UpdateConstructionTaskDeclined($scheduleId: ID!) {
  updateConstructionTaskDeclined(scheduleId: $scheduleId) {
    ${CONSTRUCTION_ATTRIBUTES}
  }
}`;

const UPDATE_TASKUSERONSITE = gql`
mutation UpdateTaskUserOnSite($taskUser: TaskUserInput!) {
  updateTaskUserOnSite(taskUser: $taskUser) {
    ${CONSTRUCTION_ATTRIBUTES}
  }
}`;

const UPDATE_TASKUSEROFFSITE = gql`
mutation UpdateTaskUserOffSite($taskUser: TaskUserInput!) {
  updateTaskUserOffSite(taskUser: $taskUser) {
    ${CONSTRUCTION_ATTRIBUTES}
  }
}`;

const UPDATE_TASKUSERCOMPLETE = gql`
mutation UpdateTaskUserComplete($taskUser: TaskUserInput!, $partial: Boolean) {
  updateTaskUserComplete(taskUser: $taskUser, partial: $partial) {
    ${CONSTRUCTION_ATTRIBUTES}
  }
}`;

const UPDATE_TASKUSER_COMMENTS = gql`
  mutation UpdateTaskUserComments($taskUser: TaskUserInput!) {
    updateTaskUserComments(taskUser: $taskUser) {
      ${CONSTRUCTION_ATTRIBUTES}
    }
  }
`;

const UPDATE_TASKSUBMITTED = gql`
mutation UpdateTaskSubmitted($scheduleId: ID!) {
  updateTaskSubmitted(scheduleId: $scheduleId) {
    ${CONSTRUCTION_ATTRIBUTES}
  }
}`;

const UPDATE_CONFIRMQTY = gql`
mutation UpdateTaskUserConfirmQty($taskUser: TaskUserInput!) {
  updateTaskUserConfirmQty(taskUser: $taskUser) {
    ${CONSTRUCTION_ATTRIBUTES}
  }
}`;

const UPDATE_TICKETOFWORK = gql`
  mutation UpdateTicketOfWork($id: ID!, $ticketOfWork: String!) {
    updateTicketOfWork(id: $id, ticketOfWork: $ticketOfWork) {
      id
      ticketOfWork
    }
  }
`;

const UPDATE_JOB_STATUS = gql`
  mutation UpdateJobStatus($id: ID!, $status: String!) {
    updateJobStatus(id: $id, status: $status) {
      id
    }
  }
`;

const UPDATE_FIELD_COMMENT = gql`
  mutation UpdateFieldComment($fieldComment: FieldCommentInput!) {
    updateFieldComment(fieldComment: $fieldComment) {
      id
      scheduleId
      comments
      createdUtc
      user {
        id
        displayName
      }
    }
  }
`;

const DELETE_FIELD_COMMENT = gql`
  mutation DeleteFieldComment($id: ID!) {
    deleteFieldComment(id: $id)
  }
`;

const UPDATE_DEVELOPER_AND_SITE_CONTACT = gql`
  mutation UpdateDeveloperAndSiteContact(
    $id: ID!
    $developer: String!
    $siteContact: String!
    $siteContactPhone: String!
  ) {
    updateDeveloperAndSiteContact(
      id: $id
      developer: $developer
      siteContact: $siteContact
      siteContactPhone: $siteContactPhone
    )
  }
`;

function MapLink({ thisaddress }) {
  const directionsUrl = `https://www.google.com/maps/search/?api=1&query=${thisaddress}`;

  return (
    <a href={directionsUrl} target="_blank" rel="noopener noreferrer">
      Location for {thisaddress}
    </a>
  );
}

const ConstructionField = ({ label, value, icon, onClick, onLabelClick, ...props }) => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' });

  const cursorStyle = { cursor: onClick || onLabelClick ? 'pointer' : 'default' };

  return (
    <Col style={{ marginBottom: '8px', ...cursorStyle }} {...props} onClick={onClick}>
      <label
        className="font-weight-bold"
        style={{
          ...cursorStyle,
          marginBottom: isSmallScreen ? '0px' : '4px'
        }}
        onClick={onLabelClick}
      >
        {icon} {label}
      </label>
      <div>
        {label === 'Site Contact:' ? (
          <a href={`tel:${value.phone ? value.phone.replace(/\s/g, '') : ''}`}>
            {value.name ?? ''} {value.phone ?? ''}
          </a>
        ) : (
          value
        )}
      </div>
    </Col>
  );
};

const Construction = (props) => {
  const { me } = useAuth();

  const [tags, setTags] = useState([]); // we'll use this for which ones are selected
  const [categories, setCategories] = useState([]); // we'll use to for which ones are available
  const [construction, setConstruction] = useState(null);
  const [fieldTask, setFieldTask] = useState(null);
  const [images, setImages] = useState([]);
  const [nonImages, setNonImages] = useState([]);
  const [groups, setGroups] = useState([]);
  const [rejectTask, setRejectTask] = useState([]);
  const [imageDetails, setImageDetails] = useState(null);
  const [isStructuredAddress, setIsStructuredAddress] = useState(false);
  const [optionalFields, setOptionalFields] = useState([]);
  const [statusSettings, setStatusSettings] = useState([]);
  const [editJobStatus, setEditJobStatus] = useState(null);
  const [editDeveloperAndContact, setEditDeveloperAndContact] = useState(null);
  const [canEditDeveloper, setCanEditDeveloper] = useState(false);
  const [canEditSiteContact, setCanEditSiteContact] = useState(null);
  const [schedulerDisplayName, setSchedulerDisplayName] = useState('Scheduler');
  const [developerDisplayName, setDeveloperDisplayName] = useState('Developer');
  const [builderList, setBuilderList] = useState([]);
  const [isAuthed, setIsAuthed] = useState(false);
  const [hasScopingBonus, setHasScopingBonus] = useState(false);

  const { loading, data, refetch, error } = useQuery(GET_CONSTRUCTION, {
    fetchPolicy: 'cache-and-network',
    variables: { constructionId: props.match.params.constructionId }
  });

  const [settingsQuery, { data: systemSettings }] = useLazyQuery(GET_FIELD_WORK_TASK_SETTINGS, {
    fetchPolicy: 'no-cache'
  });

  const [
    updateConstructionAddBOQAndItem,
    { loading: addBowAndItemUpdating, data: addBowAndItemConstruction, called: addBoqAndItemCalled }
  ] = useMutation(ADD_BOQ_AND_ITEM);
  const [updateConstructionScheduleItemCompletion] = useMutation(UPDATE_SCHEDULE_ITEM_COMPLETION);
  const [updateConstructionTaskAccepted] = useMutation(UPDATE_TASKACCEPTED);
  const [updateConstructionTaskDeclined] = useMutation(UPDATE_TASKDECLINED);
  const [updateTaskUserOnSite] = useMutation(UPDATE_TASKUSERONSITE);
  const [updateTaskUserOffSite] = useMutation(UPDATE_TASKUSEROFFSITE);
  const [updateTaskUserComplete] = useMutation(UPDATE_TASKUSERCOMPLETE);
  const [updateTaskUserConfirmQty] = useMutation(UPDATE_CONFIRMQTY);
  const [updateTaskUserComments] = useMutation(UPDATE_TASKUSER_COMMENTS);
  const [updateTaskSubmitted] = useMutation(UPDATE_TASKSUBMITTED);
  const [resetTicketOfWork] = useMutation(UPDATE_TICKETOFWORK);
  const [updateJobStatus] = useMutation(UPDATE_JOB_STATUS);
  const [updateFieldComment] = useMutation(UPDATE_FIELD_COMMENT);
  const [deleteFieldComment] = useMutation(DELETE_FIELD_COMMENT);
  const [updateDeveloperAndSiteContact] = useMutation(UPDATE_DEVELOPER_AND_SITE_CONTACT);

  const history = useHistory();

  const taskId = parseInt(props.match.params.taskId);

  useEffect(() => {
    if (data?.construction) {
      const updatedConstruction = { ...data.construction };

      const scheduleIndex = updatedConstruction.schedules.findIndex((schedule) => schedule.id === taskId);
      if (scheduleIndex !== -1) {
        const updatedSchedule = { ...updatedConstruction.schedules[scheduleIndex] };

        if (updatedSchedule.fieldComments && updatedSchedule.fieldComments.length > 0) {
          updatedSchedule.fieldComments = updatedSchedule.fieldComments.sort(
            (a, b) => new Date(b.createdUtc) - new Date(a.createdUtc)
          );
        }

        updatedConstruction.schedules[scheduleIndex] = updatedSchedule;
      }

      setConstruction(updatedConstruction);

      settingsQuery({
        variables: { workType: data.construction.workType }
      });
    }

    if (data?.groups) {
      setGroups(data.groups);
    }

    if (data?.addressTypeSetting?.length) {
      const workTypeAddressTypeData = data.addressTypeSetting.find((x) => x.workType === data.construction.workType);
      if (workTypeAddressTypeData) {
        const config = JSON.parse(workTypeAddressTypeData.config);
        setIsStructuredAddress(config?.addressSetting?.addressType === 'structured');
        setHasScopingBonus(config?.addressSetting?.hasScopingBonus);
      }
    }
  }, [data, taskId, settingsQuery]);

  useEffect(() => {
    if (systemSettings?.optionalFields?.length) {
      const _optionalFields = getFields('Constructions')
        .concat(getFields('Tasks'))
        .filter((f) => f.isOptionalFieldWorkTask)
        .map((f) => ({
          fieldName: f.fieldName,
          show: true
        }));

      const storedFields = JSON.parse(systemSettings.optionalFields[0].config).fields;
      _optionalFields.forEach((field) => {
        const storedField = storedFields.find((x) => x.fieldName === field.fieldName);
        if (storedField) {
          field.show = storedField.show;
        }
      });

      setOptionalFields(_optionalFields.filter((f) => f.show).map((f) => f.fieldName));
    }

    if (systemSettings?.fieldWorkList?.length) {
      const fieldList = JSON.parse(systemSettings.fieldWorkList[0].config).fields;
      const _canEditDeveloper = fieldList.some((f) => f.canEdit && f.fieldName === 'developer');
      setCanEditDeveloper(_canEditDeveloper);
      const _canEditSiteContact = fieldList.some((f) => f.canEdit && f.fieldName === 'siteContact');
      setCanEditSiteContact(_canEditSiteContact);
    }

    if (systemSettings?.jobStatusSettings?.length) {
      const storedStatuses = JSON.parse(systemSettings.jobStatusSettings[0].config).statuses;
      setStatusSettings(storedStatuses);
    }

    if (systemSettings?.customDisplayNames?.length) {
      const customfields = JSON.parse(systemSettings.customDisplayNames[0].config)?.fields;

      if (customfields) {
        const customScheduler = customfields.find((x) => x.fieldName === 'scheduler');
        if (customScheduler) {
          setSchedulerDisplayName(customScheduler.displayName);
        }

        const customDeveloper = customfields.find((x) => x.fieldName === 'developer');
        if (customDeveloper) {
          setDeveloperDisplayName(customDeveloper.displayName);
        }
      }
    }

    if (systemSettings?.builders) {
      const builderLookup = systemSettings.builders[0];
      setBuilderList(builderLookup?.data);
    }
  }, [systemSettings]);

  useEffect(() => {
    const temp = [];
    if (construction) {
      data.construction.attachments.forEach((a) => {
        if (a.tags) {
          a.tags.split(';').forEach((t) => {
            if (!temp.includes(t)) {
              temp.push(t);
            }
          });
        }
      });

      setCategories(temp);

      setImages(
        data.construction.attachments.filter(
          (a) => a.key.endsWith('.jpg') || a.key.endsWith('.png') || a.key.endsWith('.jpeg')
        )
      );
      setNonImages(
        data.construction.attachments.filter(
          (a) => !a.key.endsWith('.jpg') && !a.key.endsWith('.png') && !a.key.endsWith('.jpeg')
        )
      );

      const _fieldTask = construction.schedules.find((x) => x.id === taskId);
      setFieldTask(_fieldTask);

      if (me && (me.roles.includes('Administrator') || me.groupUsers.some((x) => x.group.id === _fieldTask.groupId))) {
        setIsAuthed(true);
      }
      setRejectTask(_fieldTask.items.some((i) => !!i.rejectedDate));
    }
  }, [construction, groups, me, data]);

  const shortToast = (message, props) => {
    toast(message, { ...props, toastId: 'success1', autoClose: 1500 });
  };

  const toggleStatus = (tag) => {
    if (tags.includes(tag)) {
      setTags(tags.filter((t) => t !== tag));
    } else {
      setTags(tags.concat(tag));
    }
  };

  const addBoqAndItem = useCallback(
    async (scheduleId, taskUserId, boq, item) => {
      try {
        const result = await updateConstructionAddBOQAndItem({ variables: { scheduleId, taskUserId, boq, item } });
        if (result && result.data?.updateConstructionAddBOQAndItem) {
          shortToast('Added new item to construction', { type: 'success' });
          setConstruction(result.data.updateConstructionAddBOQAndItem);
        } else {
          toast('Failed to retrieve updated construction - try refresh', { type: 'error' });
        }
      } catch (error) {
        toast('Error adding new item to construction', { type: 'error' });
      }
    },
    [updateConstructionAddBOQAndItem]
  );

  const updateScheduleItemCompletion = useCallback(
    async (item) => {
      try {
        const result = await updateConstructionScheduleItemCompletion({ variables: { item } });
        if (result && result.data?.updateConstructionScheduleItemCompletion) {
          shortToast('Changed item completion', { type: 'success' });
          setConstruction(result.data.updateConstructionScheduleItemCompletion);
        } else {
          toast('Failed to retrieve updated construction - try refresh', { type: 'error' });
        }
      } catch (error) {
        toast('Error updating completion of item', { type: 'error' });
      }
    },
    [updateConstructionScheduleItemCompletion]
  );

  const handleTaskAccepted = useCallback(
    async (scheduleId) => {
      try {
        const result = await updateConstructionTaskAccepted({ variables: { scheduleId } });
        if (result && result.data?.updateConstructionTaskAccepted) {
          shortToast('Accepted Task', { type: 'success' });
          setConstruction(result.data.updateConstructionTaskAccepted);
        } else {
          toast('Failed to retrieve updated construction - try refresh', { type: 'error' });
        }
      } catch (error) {
        toast('Error Accepting Task', { type: 'error' });
      }
    },
    [updateConstructionTaskAccepted]
  );

  const handleTaskDeclined = useCallback(
    async (scheduleId) => {
      try {
        const result = await updateConstructionTaskDeclined({ variables: { scheduleId } });
        if (result && result.data?.updateConstructionTaskDeclined) {
          shortToast('Declined Task', { type: 'success' });
          setConstruction(result.data.updateConstructionTaskDeclined);
        } else {
          toast('Failed to retrieve updated construction - try refresh', { type: 'error' });
        }
      } catch (error) {
        toast('Error Declining Task', { type: 'error' });
      }
    },
    [updateConstructionTaskDeclined]
  );

  const handleTaskUserOnSite = useCallback(
    async (scheduleId, taskUser) => {
      let _taskUser = taskUser;
      if (!taskUser) {
        _taskUser = {
          id: 0,
          userId: me.id,
          scheduleId: scheduleId,
          onSiteUtc: null,
          offSiteUtc: null,
          completedUtc: null,
          confirmItemQtys: false
        };
      }
      try {
        const result = await updateTaskUserOnSite({
          variables: { taskUser: _taskUser }
        });
        if (result && result.data?.updateTaskUserOnSite) {
          //shortToast('Set Onsite', { type: 'success' });
          setConstruction(result.data.updateTaskUserOnSite);
        } else {
          toast('Failed to retrieve updated construction - try refresh', { type: 'error' });
        }
      } catch (error) {
        toast('Error Setting Onsite', { type: 'error' });
      }
    },
    [updateTaskUserOnSite]
  );

  const handleTaskUserOffSite = useCallback(
    async (taskUser) => {
      try {
        const result = await updateTaskUserOffSite({
          variables: { taskUser }
        });
        if (result && result.data?.updateTaskUserOffSite) {
          shortToast('Set Offsite', { type: 'success' });
          setConstruction(result.data.updateTaskUserOffSite);
        } else {
          toast('Failed to retrieve updated construction - try refresh', { type: 'error' });
        }
      } catch (error) {
        toast('Error Setting Offsite', { type: 'error' });
      }
    },
    [updateTaskUserOffSite]
  );

  const handleTaskUserComplete = useCallback(
    async (taskUser, partial) => {
      try {
        const result = await updateTaskUserComplete({ variables: { taskUser, partial } });
        if (result && result.data?.updateTaskUserComplete) {
          shortToast('Task Complete', { type: 'success' });
          setConstruction(result.data.updateTaskUserComplete);
        } else {
          toast('Failed to retrieve updated construction - try refresh', { type: 'error' });
        }
      } catch (error) {
        toast('Error Completing Task', { type: 'error' });
      }
    },
    [updateTaskUserComplete]
  );

  const handleTaskSubmitted = useCallback(
    async (scheduleId) => {
      try {
        const result = await updateTaskSubmitted({ variables: { scheduleId } });
        if (result && result.data?.updateTaskSubmitted) {
          shortToast('Task Submitted', { type: 'success' });
          setConstruction(result.data.updateTaskSubmitted);
          history.push('/field/construction');
        } else {
          toast('Failed to retrieve updated construction - try refresh', { type: 'error' });
        }
      } catch (error) {
        toast('Error Setting Submitted', { type: 'error' });
      }
    },
    [updateTaskSubmitted]
  );

  const handleTaskUserConfirmQty = useCallback(
    async (taskUser) => {
      try {
        const result = await updateTaskUserConfirmQty({ variables: { taskUser } });
        if (result && result.data?.updateTaskUserConfirmQty) {
          shortToast('Item QTYs Confirmed', { type: 'success' });
          setConstruction(result.data.updateTaskUserConfirmQty);
        } else {
          toast('Failed to retrieve updated Task User - try refresh', { type: 'error' });
        }
      } catch (error) {
        toast('Error Updating Task', { type: 'error' });
      }
    },
    [updateTaskUserConfirmQty]
  );

  const handleTaskUserComments = useCallback(
    async (taskUser) => {
      try {
        const result = await updateTaskUserComments({ variables: { taskUser } });
        if (result && result.data?.updateTaskUserComments) {
          shortToast('Task User Comments Updated', { type: 'success' });
          setConstruction(result.data.updateTaskUserComments);
        } else {
          toast('Failed to retrieve updated Task User - try refresh', { type: 'error' });
        }
      } catch (error) {
        toast('Error Updating Task', { type: 'error' });
      }
    },
    [updateTaskUserComments]
  );

  const handleResetTicketOfWork = useCallback(
    async (scheduleId) => {
      try {
        const result = await resetTicketOfWork({ variables: { id: scheduleId, ticketOfWork: JSON.stringify({}) } });
        if (result && result.data?.updateTicketOfWork) {
          shortToast('Ticket of Work reset successfully', { type: 'success' });
        } else {
          toast('Failed to retrieve ticket of work - try refresh', { type: 'error' });
        }
      } catch (error) {
        toast('Error resetting Ticket of Work', { type: 'error' });
      }
    },
    [resetTicketOfWork]
  );

  const handleUpdateJobStatus = useCallback(
    async (jobId, status) => {
      try {
        const result = await updateJobStatus({
          variables: {
            id: jobId,
            status: status
          }
        });
        if (result && result.data?.updateJobStatus) {
          shortToast('Job Status updated successfully', { type: 'success' });
          setEditJobStatus(null);
          setConstruction(
            (prevJob) =>
              (prevJob = {
                ...prevJob,
                status: status
              })
          );
        } else {
          toast('Failed to update Job Status - try refresh', { type: 'error' });
        }
      } catch (error) {
        toast('Error updating Job Status', { type: 'error' });
      }
    },
    [updateJobStatus]
  );

  const handleUpdateDeveloperAndContact = useCallback(
    async (jobId, developer, siteContact, siteContactPhone) => {
      try {
        const result = await updateDeveloperAndSiteContact({
          variables: {
            id: jobId,
            developer: developer,
            siteContact: siteContact,
            siteContactPhone: siteContactPhone
          }
        });
        if (result && result.data?.updateDeveloperAndSiteContact) {
          shortToast('Developer and Site Contact updated successfully', { type: 'success' });
          setEditDeveloperAndContact(null);
          setConstruction((prevJob) => ({
            ...prevJob,
            developer: developer,
            siteContact: siteContact,
            siteContactPhone: siteContactPhone
          }));
        } else {
          toast('Failed to update - try refresh', { type: 'error' });
        }
      } catch (error) {
        toast('Error updating', { type: 'error' });
      }
    },
    [updateDeveloperAndSiteContact]
  );

  const handleUpdateFieldComment = useCallback(
    async (fieldCommentToUpdate) => {
      try {
        const { createdUtc, user, ...fieldComment } = fieldCommentToUpdate;
        const updateData = {
          ...fieldComment,
          userId: me.id
        };

        const result = await updateFieldComment({
          variables: { fieldComment: updateData },
          operationName: 'UpdateFieldComment'
        });

        if (result && result.data?.updateFieldComment) {
          const updatedComment = {
            ...result.data.updateFieldComment,
            createdUtc: result.data.updateFieldComment.createdUtc.endsWith('Z')
              ? result.data.updateFieldComment.createdUtc.slice(0, -1)
              : result.data.updateFieldComment.createdUtc
          };

          setConstruction((prevConstruction) => {
            const schedules = [...prevConstruction.schedules];
            const scheduleIndex = schedules.findIndex((schedule) => schedule.id === taskId);

            if (scheduleIndex !== -1) {
              const updatedSchedule = { ...schedules[scheduleIndex] };

              const commentIndex = updatedSchedule.fieldComments.findIndex(
                (comment) => comment.id === updatedComment.id
              );
              if (commentIndex !== -1) {
                updatedSchedule.fieldComments[commentIndex] = updatedComment;
              } else {
                updatedSchedule.fieldComments.push(updatedComment);
              }
              updatedSchedule.fieldComments.sort((a, b) => new Date(b.createdUtc) - new Date(a.createdUtc));

              schedules[scheduleIndex] = updatedSchedule;
            }

            return { ...prevConstruction, schedules };
          });
        } else {
          toast('Failed to update field comment - try refresh', { type: 'error' });
        }
      } catch (error) {
        toast('Error updating field comment', { type: 'error' });
      }
    },
    [updateFieldComment, taskId]
  );

  const handleDeleteFieldComment = useCallback(
    async (fieldCommentId) => {
      try {
        const result = await deleteFieldComment({
          variables: { id: fieldCommentId }
        });

        if (result) {
          setConstruction((prevConstruction) => {
            const schedules = [...prevConstruction.schedules];
            const scheduleIndex = schedules.findIndex((schedule) => schedule.id === taskId);

            if (scheduleIndex !== -1) {
              const updatedSchedule = { ...schedules[scheduleIndex] };
              updatedSchedule.fieldComments = updatedSchedule.fieldComments.filter(
                (comment) => comment.id !== fieldCommentId
              );
              schedules[scheduleIndex] = updatedSchedule;
            }

            return { ...prevConstruction, schedules };
          });
        } else {
          toast('Failed to delete field comment - try refresh', { type: 'error' });
        }
      } catch (error) {
        toast('Error deleting field comment', { type: 'error' });
      }
    },
    [deleteFieldComment, taskId]
  );

  const checkIsSubcontractor = (groupId) => {
    const group = groups.find((x) => x.id === groupId);
    return !!group?.isSubcontractor;
  };

  const onImageEdit = (newImageDetails) => {
    const image = images.filter((x) => x.id === newImageDetails.id)[0];
    image.title = newImageDetails.title;
    image.url = newImageDetails.url;
    image.comments = newImageDetails.comments;
    setImages([...images]);
  };

  return (
    <>
      <Sync></Sync>

      {construction && systemSettings && fieldTask && (
        <>
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col">
                  <h5>{construction.workOrder}</h5>
                </div>
                <div className="col">
                  <div className="text-right">
                    <Link className="btn btn-sm btn-secondary " to="/field/construction">
                      Back to task list
                    </Link>{' '}
                    {me.roles.includes('Administrator') && (
                      <Link className="btn btn-sm btn-secondary" to={`/construction/${construction.id}`}>
                        Full Construction Job
                      </Link>
                    )}
                  </div>
                </div>
              </div>

              <MapLink thisaddress={isStructuredAddress ? getAddress(construction) : construction.address} />
            </div>
            <div className="card-body">
              <Row>
                <ConstructionField
                  md={2}
                  label="Status:"
                  value={construction.status}
                  onClick={
                    statusSettings?.some((status) => status.fieldEdit)
                      ? () => setEditJobStatus(construction)
                      : undefined
                  }
                  icon={statusSettings?.some((status) => status.fieldEdit) ? <i className="icon-pencil" /> : null}
                />
                {optionalFields.includes('description') && (
                  <ConstructionField md={2} label="Description:" value={construction.description} />
                )}
                {optionalFields.includes('workType') && (
                  <ConstructionField md={2} label="Work Type:" value={construction.workType} />
                )}
                {optionalFields.includes('region') && (
                  <ConstructionField md={2} label="Region:" value={construction.region} />
                )}
                {optionalFields.includes('deliveryPartner') && (
                  <ConstructionField md={2} label="DP:" value={construction.deliveryPartner} />
                )}
                {optionalFields.includes('deliveryPartnerPm') && (
                  <ConstructionField md={2} label="DP PM:" value={construction.deliveryPartnerPm} />
                )}
                {optionalFields.includes('scheduler') && (
                  <ConstructionField md={2} label={schedulerDisplayName} value={construction.scheduler} />
                )}
                {optionalFields.includes('developer') && (
                  <ConstructionField
                    md={2}
                    label={developerDisplayName}
                    value={construction.developer}
                    onClick={
                      canEditDeveloper || canEditSiteContact
                        ? () => setEditDeveloperAndContact(construction)
                        : undefined
                    }
                    icon={canEditDeveloper || canEditSiteContact ? <i className="icon-pencil" /> : null}
                  />
                )}
                {optionalFields.includes('buildType') && (
                  <ConstructionField md={2} label="Build Type:" value={construction.buildType} />
                )}
                {optionalFields.includes('acceleratedJob') && (
                  <ConstructionField
                    md={2}
                    label="Accelerated Job:"
                    value={construction.acceleratedJob ? 'Yes' : 'No'}
                  />
                )}
                {optionalFields.includes('dueDate') && (
                  <ConstructionField md={2} label="Due Date:" value={formatDate(fieldTask.dueDate)} />
                )}
                {optionalFields.includes('onsiteDate') && (
                  <ConstructionField md={2} label="Onsite Date:" value={formatDate(fieldTask.onsiteDate)} />
                )}
                {optionalFields.includes('integrationDate') && (
                  <ConstructionField
                    md={2}
                    label="Integration Date:"
                    value={formatDate(construction.integrationDate)}
                  />
                )}
                {optionalFields.includes('dbydExpiryDate') && (
                  <ConstructionField md={2} label="DBYD Expiry:" value={formatDate(construction.dbydExpiryDate)} />
                )}
                {optionalFields.includes('laseDate') && (
                  <ConstructionField md={2} label="LASE Date:" value={formatDate(construction.laseDate)} />
                )}
                {optionalFields.includes('crqDate') && (
                  <ConstructionField md={2} label="CRQ Date:" value={formatDate(construction.crqDate)} />
                )}
                {optionalFields.includes('crqNumber') && (
                  <ConstructionField md={2} label="CRQ Number:" value={construction.crqNumber} />
                )}
                {optionalFields.includes('siteContactPhone') && (
                  <ConstructionField
                    md={2}
                    label="Site Contact:"
                    value={{ name: construction.siteContact, phone: construction.siteContactPhone }}
                    onLabelClick={
                      canEditDeveloper || canEditSiteContact
                        ? () => setEditDeveloperAndContact(construction)
                        : undefined
                    }
                    icon={canEditDeveloper || canEditSiteContact ? <i className="icon-pencil" /> : null}
                  />
                )}
                {optionalFields.includes('customerRef') && (
                  <ConstructionField md={2} label="Customer Ref:" value={construction.customerRef} />
                )}
              </Row>
              {hasScopingBonus && (
                <div
                  className={`alert ${
                    construction.developer && construction.siteContactPhone ? 'alert-success' : 'alert-warning'
                  }`}
                >
                  <span className="h5 font-weight-bold mb-0">
                    {construction.developer && construction.siteContactPhone
                      ? 'Scoping complete'
                      : 'To claim a scoping bonus, please ensure Developer and Site Contact Phone number are updated.'}
                  </span>
                </div>
              )}
            </div>
            {editJobStatus && (
              <Modal isOpen={!!editJobStatus} backdrop={true} toggle={() => setEditJobStatus(null)}>
                <ModalHeader>Update Job Status</ModalHeader>
                <ModalBody>
                  <div>{isStructuredAddress ? getAddress(editJobStatus) : <>{editJobStatus.address}</>}</div>
                  <hr />
                  <div>
                    <select
                      className="form-control"
                      value={editJobStatus.status}
                      onChange={(e) => setEditJobStatus({ ...editJobStatus, status: e.target.value })}
                    >
                      {statusSettings
                        .filter((x) => x.fieldEdit)
                        .map((status) => (
                          <option key={status.statusName} value={status.statusName}>
                            {status.statusName}
                          </option>
                        ))}
                    </select>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <button className="btn btn-primary" onClick={() => setEditJobStatus(null)}>
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      handleUpdateJobStatus(editJobStatus.id, editJobStatus.status);
                    }}
                  >
                    Update
                  </button>
                </ModalFooter>
              </Modal>
            )}
            {editDeveloperAndContact && (
              <Modal isOpen={!!editDeveloperAndContact} backdrop={true} toggle={() => setEditDeveloperAndContact(null)}>
                <ModalHeader>Edit Developer and Site Contact</ModalHeader>
                <ModalBody>
                  <div>
                    {isStructuredAddress ? getAddress(editDeveloperAndContact) : editDeveloperAndContact.address}
                  </div>
                  <hr />
                  <div className="py-2">
                    <label>{developerDisplayName}</label>
                    <select
                      className="form-control"
                      value={editDeveloperAndContact.developer || ''}
                      onChange={(e) =>
                        setEditDeveloperAndContact({
                          ...editDeveloperAndContact,
                          developer: e.target.value
                        })
                      }
                    >
                      <option value="">Select Option...</option>
                      {builderList.map((builder) => (
                        <option key={builder} value={builder}>
                          {builder}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="py-2">
                    <label>Site Contact Name</label>
                    <input
                      className="form-control"
                      defaultValue={editDeveloperAndContact.siteContact}
                      onBlur={(e) =>
                        setEditDeveloperAndContact({
                          ...editDeveloperAndContact,
                          siteContact: e.target.value
                        })
                      }
                    />
                  </div>
                  <div className="py-2">
                    <label>Site Contact Phone</label>
                    <input
                      className="form-control"
                      defaultValue={editDeveloperAndContact.siteContactPhone}
                      onBlur={(e) =>
                        setEditDeveloperAndContact({
                          ...editDeveloperAndContact,
                          siteContactPhone: e.target.value
                        })
                      }
                    />
                  </div>
                </ModalBody>
                <ModalFooter>
                  <button className="btn btn-primary" onClick={() => setEditDeveloperAndContact(null)}>
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      handleUpdateDeveloperAndContact(
                        editDeveloperAndContact.id,
                        editDeveloperAndContact.developer,
                        editDeveloperAndContact.siteContact,
                        editDeveloperAndContact.siteContactPhone
                      );
                    }}
                  >
                    Update
                  </button>
                </ModalFooter>
              </Modal>
            )}
          </div>

          {isAuthed && (
            <div className={`card ${rejectTask ? 'border border-danger' : ''}`}>
              <div className={`card-header ${rejectTask ? 'bg-danger' : ''}`}>
                {rejectTask ? 'Scheduled Task has rejected item quantities' : 'Scheduled Task'}
              </div>
              <div className="card-body p-2">
                <ConstructionSchedule
                  construction={construction}
                  schedule={fieldTask}
                  onAddItem={addBoqAndItem}
                  onChangeItem={updateScheduleItemCompletion}
                  handleTaskAccepted={handleTaskAccepted}
                  handleTaskDeclined={handleTaskDeclined}
                  handleTaskUserOnSite={handleTaskUserOnSite}
                  handleTaskUserComplete={handleTaskUserComplete}
                  handleTaskSubmitted={handleTaskSubmitted}
                  handleTaskUserConfirmQty={handleTaskUserConfirmQty}
                  handleTaskUserOffSite={handleTaskUserOffSite}
                  handleTaskUserComments={handleTaskUserComments}
                  handleResetTicketOfWork={handleResetTicketOfWork}
                  handleUpdateFieldComment={handleUpdateFieldComment}
                  handleDeleteFieldComment={handleDeleteFieldComment}
                  isSubcontractor={checkIsSubcontractor(fieldTask.groupId)}
                  isFinanceOrSub={me.isSubcontractor || me.roles.includes('Finance')}
                  isReject={rejectTask}
                />
              </div>
            </div>
          )}

          <div className="card">
            <div className="card-header">Attachments</div>
            <div className="card-body">
              <div className="mb-1">
                {categories.map((category, index) => {
                  return (
                    <button
                      key={index}
                      className={'btn btn-sm mb-1 mr-1 ' + (tags.includes(category) ? 'btn-success' : 'btn-secondary')}
                      onClick={() => toggleStatus(category)}
                    >
                      {category}
                    </button>
                  );
                })}
              </div>

              {nonImages.map((attachment) => {
                const aTags = attachment.tags ? attachment.tags.split(';') : [];
                var selected = false;

                aTags.forEach((t) => {
                  if (tags.includes(t)) {
                    selected = true;
                  }
                });
                return selected ? (
                  <div key={attachment.id}>
                    <a target="_blank" href={attachment.url}>
                      {attachment.title}
                    </a>
                  </div>
                ) : null;
              })}
              {images && (
                <div className="row">
                  {images.map((image, index) => {
                    const aTags = image.tags ? image.tags.split(';') : [];
                    var selected = false;

                    aTags.forEach((t) => {
                      if (tags.includes(t)) {
                        selected = true;
                      }
                    });
                    return selected ? (
                      <div className="col-lg-12 col-xl-6 mb-2" key={image.id}>
                        {/* <img src={image.url} className="card-img-top" alt="..." /> */}
                        <LazyImg
                          src={image.url}
                          crossOrigin="anonymous"
                          className="card-img-top"
                          alt="..."
                          onClick={() => {
                            setImageDetails(image);
                          }}
                        />
                        <div className="pl-1 mb-1" style={{ fontSize: '18px' }}>
                          {image.comments}
                        </div>
                      </div>
                    ) : null;
                  })}
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {imageDetails && (
        <EditPhoto
          imageDetails={imageDetails}
          onImageEdit={(newImageDetails) => onImageEdit(newImageDetails)}
          onClose={() => setImageDetails(null)}
        />
      )}
    </>
  );
};

const ConstructionSchedule = ({
  construction,
  schedule,
  onAddItem,
  onChangeItem,
  handleTaskAccepted,
  handleTaskDeclined,
  handleTaskUserOnSite,
  handleTaskUserComplete,
  handleTaskSubmitted,
  handleTaskUserConfirmQty,
  handleTaskUserOffSite,
  handleTaskUserComments,
  handleResetTicketOfWork,
  handleUpdateFieldComment,
  handleDeleteFieldComment,
  isSubcontractor,
  isFinanceOrSub,
  isReject
}) => {
  const { token, me } = useAuth();

  const [scheduleItems, setScheduleItems] = useState([]);

  const [showOnSite, setShowOnSite] = useState(false);
  const [showComplete, setShowComplete] = useState(false);
  const [taskUser, setTaskUser] = useState(null);
  const [taskUserComments, setTaskUserComments] = useState([]);
  const [confirmItemQtyOnComplete, setConfirmItemQtyOnComplete] = useState(false);
  const [showClaimCommentEdit, setShowClaimCommentEdit] = useState(false);
  const [editFieldComment, setEditFieldComment] = useState(null);
  const [showPartialCompleteComments, setShowPartialCompleteComments] = useState(false);
  const [commentsUpdated, setCommentsUpdated] = useState(false);
  const [showOnsiteModel, setShowOnsiteModel] = useState(false);

  useEffect(() => {
    if (commentsUpdated) {
      handleComplete(true);
      setCommentsUpdated(false);
    }
  }, [commentsUpdated]);

  useEffect(() => {
    if (isSubcontractor) {
      setScheduleItems(
        schedule.items
          .filter((i) => i.childCode)
          .sort((a, b) => b.boqId - a.boqId || (a.childCode ?? '').localeCompare(b.childCode ?? ''))
      );
    } else {
      setScheduleItems(schedule.items.filter((i) => !i.childCode).sort((a, b) => b.boqId - a.boqId));
    }
  }, [isSubcontractor, schedule, schedule.items]);

  useEffect(() => {
    let _taskUser = {
      ...schedule.taskUsers
        .filter((x) => x.userId === me.id)
        .sort((a, b) => new Date(b.onSiteUtc) - new Date(a.onSiteUtc))?.[0]
    };
    _taskUser = _taskUser.id ? _taskUser : null;
    const taskUsers = schedule.taskUsers
      .filter((x) => !!x.comments)
      .sort((a, b) => new Date(b.onSiteUtc) - new Date(a.onSiteUtc));
    setTaskUserComments(taskUsers?.filter((x) => !!x.comments));
    delete _taskUser?.user;
    setTaskUser(_taskUser);
    if (!schedule.completedDate) {
      if (!_taskUser || _taskUser.offSiteUtc || _taskUser.completedUtc) {
        setShowComplete(false);
        if (!_taskUser?.completedUtc || schedule.isReopened) {
          setShowOnSite(true);
        }
      } else if (!_taskUser.completedUtc) {
        setShowComplete(true);
      }
    }
  }, [schedule]);

  const handleComplete = (partial = false) => {
    setShowComplete(false);
    handleTaskUserComplete(taskUser, partial);
  };

  const checkSubmit = (scheduleId) => {
    if (taskUser && !taskUser.confirmItemQtys && !!scheduleItems && scheduleItems.length > 0) {
      setConfirmItemQtyOnComplete(true);
    } else {
      handleTaskSubmitted(scheduleId);
    }
  };

  return (
    <>
      <div
        className={
          'list-group-item list-group-item-primary p-2' +
          (schedule.completedDate && !isReject ? ' list-group-item-success' : '')
        }
      >
        <div className="row">
          <div className={`col-xl-5 col-lg-12 ${schedule.completedDate && !isReject ? 'text-success' : ''}`}>
            {schedule.name}
          </div>
          <div className="col-xl-2 col-lg-12 text-center p-0 mb-4">
            {!(schedule.acceptedDate || schedule.declinedDate) && !schedule.completedDate && isSubcontractor && (
              <>
                <button className="btn btn-primary mr-1" onClick={() => handleTaskAccepted(schedule.id)}>
                  Accept
                </button>{' '}
                <button className="btn btn-primary" onClick={() => handleTaskDeclined(schedule.id)}>
                  Decline
                </button>
              </>
            )}
            {schedule.acceptedDate && <div>Date Accepted: {schedule.acceptedDate.substr(0, 10)}</div>}
            {schedule.declinedDate && <div>Date Declined: {schedule.declinedDate.substr(0, 10)}</div>}
          </div>
          <div className="col-xl-5 col-lg-12 text-right">
            {(!isSubcontractor || schedule.acceptedDate) && schedule.completedDate && !schedule.submittedDate && (
              <button className="btn btn-primary" onClick={() => checkSubmit(schedule.id)}>
                Submit
              </button>
            )}{' '}
            {(!isSubcontractor || schedule.acceptedDate) && (
              <>
                {showComplete ? (
                  <>
                    <button className="btn btn-primary mb-1" onClick={() => setShowPartialCompleteComments(true)}>
                      Is Partially Complete
                    </button>{' '}
                    <button className="btn btn-primary mb-1" onClick={() => handleComplete()}>
                      Is Complete
                    </button>{' '}
                    <button className="btn btn-primary mb-1" onClick={() => handleTaskUserOffSite(taskUser)}>
                      I'm Offsite
                    </button>{' '}
                    {schedule.externalJobLink && (
                      <button className="btn btn-primary mb-1" onClick={() => setShowOnsiteModel(true)}>
                        Start Card
                      </button>
                    )}
                  </>
                ) : (
                  showOnSite &&
                  !schedule.completedDate && (
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        handleTaskUserOnSite(schedule.id, taskUser);
                        if (schedule.externalJobLink) {
                          setShowOnsiteModel(true);
                        }
                      }}
                    >
                      I'm Onsite
                    </button>
                  )
                )}

                <div className="mt-4">
                  <Link
                    className="btn btn-primary mb-4"
                    to={'/field/construction/' + construction.id + '/capture2/' + schedule.id}
                  >
                    Capture Images
                  </Link>{' '}
                  <Link
                    className="btn btn-primary mb-4"
                    style={{ marginLeft: '90px' }}
                    to={'/field/construction/' + construction.id + '/' + schedule.id + '/attachments'}
                  >
                    Upload files
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
        {!!schedule.comments && schedule.comments !== '' && (
          <div className="row">
            <div className="col">
              <div className="mt-2">
                <strong>Comments: </strong> {schedule.comments}
              </div>
            </div>
          </div>
        )}

        {!!scheduleItems && scheduleItems.length > 0 && (
          <div className="row p-0 m-0">
            <div className="col p-0 pt-3">
              <ConstructionScheduleItems
                construction={construction}
                scheduleItems={scheduleItems}
                schedule={schedule}
                taskUser={taskUser}
                onAddItem={onAddItem}
                onChangeItem={onChangeItem}
                isSubcontractor={isSubcontractor}
                handleTaskUserConfirmQty={handleTaskUserConfirmQty}
                handleResetTicketOfWork={handleResetTicketOfWork}
                isFinanceOrSub={isFinanceOrSub}
                isReject={isReject}
              />
            </div>
          </div>
        )}

        <div className="pt-2">
          <div className="p-2" style={{ borderRadius: '4px', backgroundColor: '#FFFDF0' }}>
            <Button onClick={() => setEditFieldComment({ scheduleId: schedule.id })}>Add Field Comment</Button>
            {schedule.fieldComments && schedule.fieldComments.length > 0 ? (
              <div>
                {schedule.fieldComments.map((comment, index) => {
                  const isCommentOwner = me.id === comment.user?.id;

                  return (
                    <div key={index} className="row">
                      <div className="col py-1">
                        {isCommentOwner ? (
                          <Button
                            onClick={() => setEditFieldComment(comment)}
                            style={{
                              background: 'none',
                              border: 'none',
                              color: 'black',
                              padding: 0,
                              margin: 0,
                              textDecoration: 'none',
                              boxShadow: 'none',
                              cursor: 'pointer'
                            }}
                            className="text-left font-weight-bold p-0"
                          >
                            <i className="icon-pencil" /> {formatDateTime(comment.createdUtc) + ': '}
                          </Button>
                        ) : (
                          <strong>{formatDateTime(comment.createdUtc) + ': '}</strong>
                        )}{' '}
                        {comment.comments}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>No Field Comments</div>
            )}
            {schedule.completedDate && !schedule.submittedDate ? (
              <Button onClick={() => setShowClaimCommentEdit(true)}>
                {taskUserComments?.length && taskUserComments[0].id === taskUser?.id ? 'Edit' : 'Add'} Completions
                Comment
              </Button>
            ) : (
              <>{taskUserComments && taskUserComments.length > 0 && <h4>Completions Comment</h4>}</>
            )}
            {taskUserComments &&
              taskUserComments.length > 0 &&
              taskUserComments.map((tuc, index) => {
                return (
                  <div key={index} className="row">
                    <div className="col py-1">
                      <strong>
                        {formatDate(tuc.onSiteUtc) + ' - '} {tuc.user?.displayName + ': '}
                      </strong>
                      {tuc.comments}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <Modal isOpen={confirmItemQtyOnComplete}>
        <ModalHeader>Please Confirm item quantities</ModalHeader>
        <ModalFooter>
          <Button onClick={() => setConfirmItemQtyOnComplete(false)}>Close</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={showClaimCommentEdit}>
        <ModalHeader>Comments</ModalHeader>
        <ModalBody>
          <textarea
            className="form-control"
            rows="5"
            value={taskUser?.comments ?? ''}
            onChange={(e) =>
              setTaskUser({
                ...taskUser,
                comments: e.target.value
              })
            }
          ></textarea>
        </ModalBody>
        <ModalFooter>
          <Button className="ml-2" onClick={() => setShowClaimCommentEdit(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleTaskUserComments(taskUser);
              setShowClaimCommentEdit(false);
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>

      {editFieldComment && (
        <Modal isOpen={editFieldComment !== null} backdrop={true} toggle={() => setEditFieldComment(null)}>
          <ModalHeader>Edit Field Comment</ModalHeader>
          <ModalBody>
            <div>
              <textarea
                className="form-control"
                value={editFieldComment.comments}
                onChange={(e) =>
                  setEditFieldComment({
                    ...editFieldComment,
                    comments: e.target.value
                  })
                }
              />
            </div>
          </ModalBody>
          <ModalFooter>
            {editFieldComment.id && (
              <Button
                color="danger"
                onClick={() => {
                  handleDeleteFieldComment(editFieldComment.id);
                  setEditFieldComment(null);
                }}
                className="mr-auto"
              >
                Delete
              </Button>
            )}
            <Button color="secondary" onClick={() => setEditFieldComment(null)}>
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() => {
                handleUpdateFieldComment(editFieldComment);
                setEditFieldComment(null);
              }}
            >
              Update
            </Button>
          </ModalFooter>
        </Modal>
      )}

      <Modal isOpen={showPartialCompleteComments}>
        <ModalHeader>
          Comments
          <div style={{ fontSize: '14px', fontWeight: '400' }}>Partially Complete Reason</div>
        </ModalHeader>
        <ModalBody>
          <textarea
            className="form-control"
            rows="5"
            value={taskUser?.comments ?? ''}
            onChange={(e) =>
              setTaskUser({
                ...taskUser,
                comments: e.target.value
              })
            }
          ></textarea>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setShowPartialCompleteComments(false)}>Cancel</Button>
          <Button
            disabled={!taskUser?.comments}
            color={taskUser?.comments ? 'primary' : 'secondary'}
            onClick={async () => {
              await handleTaskUserComments(taskUser);
              setCommentsUpdated(true);
              setShowPartialCompleteComments(false);
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
      {/* Onsite Model: show a message saying "This will take you to the job in Novus" and Start Card link button */}
      <Modal isOpen={showOnsiteModel}>
        <ModalHeader>Onsite</ModalHeader>
        <ModalBody>
          <div>
            <p>
              Click the Start Card button below
              <br />
              This will take you to the Novus Login page
              <br />
              After logging in it will redirect to the job page
            </p>
            <p>
              1) First click the yellow "Enroute" button, it will then change to "Onsite"
              <br />
              2) Click the Onsite button
              <br />
              3) Then click the "Forms" tab
              <br />
              4) Click the "SJLR iStart Card" (most recent if more than one)
              <br />
              5) On the Start Card form:
              <br /> - Fill in First and Last name
              <br /> - Click "Today" and "Now" For the Date and Time
              <br /> - Sign at the bottom and click "Submit"
            </p>
          </div>
          {schedule.externalJobLink && (
            <a className="btn btn-success" target="_blank" href={schedule.externalJobLink}>
              Start Card
            </a>
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setShowOnsiteModel(false)}>Close</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const ConstructionScheduleItems = ({
  construction,
  scheduleItems,
  schedule,
  onAddItem,
  onChangeItem,
  isSubcontractor,
  handleTaskUserConfirmQty,
  handleResetTicketOfWork,
  taskUser,
  isFinanceOrSub,
  isReject
}) => {
  const { me } = useAuth();

  const [boqsById, setBoqsById] = useState({});
  const [addItem, setAddItem] = useState(false);
  const [changeItem, setChangeItem] = useState(undefined);
  const [getChildCodes, { loading, data: childCodes, refetch, error }] = useLazyQuery(GET_ACTIVEITEMCHILDCODES, {
    fetchPolicy: 'no-cache'
  });
  const [childItemsByCode, setChildItemsByCode] = useState({});

  const canEditItems = !isSubcontractor || isReject || (schedule.acceptedDate && taskUser && !schedule.submittedDate);
  const canConfirm =
    !isSubcontractor ||
    (schedule.acceptedDate &&
      taskUser &&
      !taskUser.confirmItemQtys &&
      schedule.completedDate &&
      !schedule.submittedDate);
  useEffect(() => {
    if (isSubcontractor) {
      getChildCodes({
        variables: {
          region: construction.region,
          fromDateUtc: construction.releaseDate,
          contractor: schedule?.resource?.split(':')?.[1]
          //codes: construction?.boqs?.map(b => b.code)
        }
      });
    }
  }, [isSubcontractor, schedule, getChildCodes]);

  useEffect(() => {
    if (construction.boqs) {
      // Map boqs by Id
      const newBoqsById = {};

      // Get boqs with unassigned qty
      const newUnassignedBoqs = [];
      const newChildItemsByCode = {};

      construction.boqs.forEach((boq) => {
        const hasBoq = !!scheduleItems?.find((i) => i.boqId === boq.id);
        const assignedQty = construction.schedules.reduce(
          (acc, v) => acc + (v.items?.find((i) => i.boqId === boq.id)?.quantity ?? 0),
          0
        );
        newBoqsById[boq.id] = {
          ...boq,
          assignedQty,
          unassignedQty: (boq.variationQty ?? boq.quantity) - assignedQty
        };

        if (childCodes) {
          const boqChildCodes = childCodes.subcontractorItemCodes.filter((item) => item.parentCode === boq.code);

          boqChildCodes.forEach((ci) => {
            const boq = construction.boqs.find((b) => b.code === ci.parentCode);

            newChildItemsByCode[`${ci.parentCode}-${ci.childCode}`] = ci;
          });
        }
      });
      //setUnassignedBoqs(newUnassignedBoqs);
      setChildItemsByCode(newChildItemsByCode);
      setBoqsById(newBoqsById);

      // let anyInvalid = !!scheduleItems?.find((item) => {
      //   const boq = newBoqsById[item.boqId];
      //   if (boq) {
      //     if (boq.unassignedQty < 0) {
      //       anyInvalid = true;
      //       return true;
      //     }
      //   }
      // });
      //setInvalid(anyInvalid);
    } else {
      //setUnassignedBoqs([]);
    }
  }, [childCodes?.subcontractorItemCodes, construction.schedules, scheduleItems]);

  const getComments = (item) => {
    const payment = construction.payments.find((x) => x.name === schedule.name && x.owner === schedule.resource);
    return payment?.paymentItems?.find((x) => x.itemCode === item.childCode)?.comments;
  };

  const calcTotal = (item) => {
    if (!item.rate) return '';
    if (item.completedQty === 0) return '';
    if (item.completedQty) {
      return item.rate * item.completedQty;
    }
    if (item.quantity === 0) return '';
    if (item.quantity) {
      return item.rate * item.quantity;
    }
    return '';
  };

  return (
    <>
      <div className="pl-1">Items</div>
      <div style={{ backgroundColor: 'white' }}>
        <MediaQuery maxWidth={600}>
          <Table responsive hover striped>
            <thead>
              <tr>
                <th style={{ width: '130px' }}>
                  Code/Type
                  <br />
                  Qty/UOM
                  {isFinanceOrSub && (
                    <>
                      <br />
                      Rate
                      <br />
                      Total
                    </>
                  )}
                </th>
                <th>Description</th>
                <th>Variation Reason</th>
                <th>Comments</th>
              </tr>
            </thead>
            <tbody>
              {scheduleItems?.map((item, bIndex) => {
                const boq = boqsById[item.boqId];
                if (boq) {
                  const childCode = isSubcontractor ? childItemsByCode[`${boq.code}-${item.childCode}`] : null;
                  return (
                    <tr key={bIndex}>
                      <td style={{ width: '130px' }}>
                        {childCode?.childCode ?? boq.code}
                        <br />
                        {childCode?.itemType ?? boq.itemType}
                        <div>
                          <span className={isReject && item.rejectedDate ? 'bg-danger px-2' : ''}>
                            <strong>{item.completedQty ?? item.quantity ?? 0}</strong>{' '}
                            {childCode?.unitOfMeasure ?? boq.unitOfMeasure}
                          </span>
                          {isFinanceOrSub && (
                            <>
                              <br />
                              {Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(item.rate)}
                              <br />
                              <strong>
                                {Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(
                                  calcTotal(item)
                                )}
                              </strong>
                            </>
                          )}
                          <br />
                          {(canEditItems || (isReject && item.rejectedDate)) && (
                            <a
                              href="#"
                              tabIndex="-1"
                              onClick={(e) => {
                                setChangeItem(item);
                                e.preventDefault();
                              }}
                            >
                              change
                            </a>
                          )}
                        </div>
                      </td>
                      <td>{childCode?.description ?? boq.description}</td>
                      <td>{item.variationReason}</td>
                      <td>{getComments(item)}</td>
                    </tr>
                  );
                } else {
                  return null;
                }
              })}
            </tbody>
          </Table>
        </MediaQuery>
        <MediaQuery minWidth={601}>
          <Table responsive hover striped>
            <thead>
              <tr>
                <th style={{ width: '130px' }}>Code</th>
                <th>Type</th>
                <th>Qty/UOM</th>
                {isFinanceOrSub && <th>Rate</th>}
                {isFinanceOrSub && <th>Total</th>}
                <th>Description</th>
                <th>Variation Reason</th>
                <th>Comments</th>
              </tr>
            </thead>
            <tbody>
              {scheduleItems?.map((item, bIndex) => {
                const boq = boqsById[item.boqId];
                if (boq) {
                  const childCode = isSubcontractor ? childItemsByCode[`${boq.code}-${item.childCode}`] : null;
                  return (
                    <tr key={bIndex}>
                      <td style={{ width: '130px' }}>{childCode?.childCode ?? boq.code}</td>
                      <td>{childCode?.itemType ?? boq.itemType}</td>
                      <td>
                        <span className={isReject && item.rejectedDate ? 'bg-danger px-2' : ''}>
                          <strong>{item.completedQty ?? item.quantity ?? 0}</strong>{' '}
                          {childCode?.unitOfMeasure ?? boq.unitOfMeasure}
                        </span>
                        <br />
                        {(canEditItems || (isReject && item.rejectedDate)) && (
                          <a
                            href="#"
                            tabIndex="-1"
                            onClick={(e) => {
                              setChangeItem(item);
                              e.preventDefault();
                            }}
                          >
                            change
                          </a>
                        )}
                      </td>
                      {isFinanceOrSub && (
                        <td>{Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(item.rate)}</td>
                      )}
                      {isFinanceOrSub && (
                        <td>
                          {Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(calcTotal(item))}
                        </td>
                      )}
                      <td>{childCode?.description ?? boq.description}</td>
                      <td>{item.variationReason}</td>
                      <td>{getComments(item)}</td>
                    </tr>
                  );
                } else {
                  return null;
                }
              })}
            </tbody>
          </Table>
        </MediaQuery>
      </div>
      {canEditItems && (
        <Button className="mt-1" onClick={() => setAddItem(true)}>
          Add new item
        </Button>
      )}{' '}
      {canConfirm && (
        <Button className="mt-1" onClick={() => handleTaskUserConfirmQty(taskUser)}>
          Confirm
        </Button>
      )}{' '}
      <a href={'/field/task/' + construction.id + '/' + schedule.id + '/ticket-of-work'} target="_blank">
        <Button className="mt-1">{schedule.ticketOfWork ? 'Ticket of Work' : 'Create Ticket of Work'}</Button>
      </a>{' '}
      {me.roles.includes('Administrator') && (
        <Button className="mt-1 float-right" onClick={() => handleResetTicketOfWork(schedule.id)}>
          Reset Ticket of Work
        </Button>
      )}
      {!!addItem && (
        <AddNewConstructionScheduleItem
          construction={construction}
          schedule={schedule}
          onClose={() => setAddItem(false)}
          childCodes={childCodes}
          isSubcontractor={isSubcontractor}
          onAdd={async (item) => {
            await onAddItem(
              schedule.id,
              taskUser?.id ?? 0,
              item.boq ??
                (isSubcontractor
                  ? {
                      id: 0,
                      code: item.code,
                      description: item.description,
                      itemType: item.itemType,
                      unitOfMeasure: item.unitOfMeasure,
                      quantity: 0,
                      variationQty: 0,
                      assignedQty: 0,
                      completedQty: 0
                    }
                  : {
                      id: 0,
                      code: item.code,
                      description: item.description,
                      itemType: item.itemType,
                      unitOfMeasure: item.unitOfMeasure,
                      quantity: 0,
                      variationQty: item.variationQty,
                      assignedQty: item.variationQty,
                      completedQty: item.variationQty
                    }),
              {
                id: 0,
                boqId: item.boq?.id ?? 0,
                childCode: item.childCode,
                variationReason: item.reason,
                quantity: 0,
                completedQty: item.variationQty
              }
            );
            setAddItem(false);
          }}
        />
      )}
      {changeItem && (
        <ChangeConstructionScheduleItem
          construction={construction}
          schedule={schedule}
          boq={boqsById[changeItem.boqId]}
          childCode={
            isSubcontractor ? childItemsByCode[`${boqsById[changeItem.boqId]?.code}-${changeItem.childCode}`] : null
          }
          item={changeItem}
          onClose={() => setChangeItem(undefined)}
          onChange={async (item) => {
            await onChangeItem(item, taskUser?.id ?? 0);
            setChangeItem(undefined);
          }}
        />
      )}
    </>
  );
};

const AddNewConstructionScheduleItem = ({
  construction,
  schedule,
  boqsById,
  childCodes,
  onAdd,
  onClose,
  isSubcontractor
}) => {
  const [itemCodes, setItemCodes] = useState([]);
  const [itemCodeOptions, setItemCodeOptions] = useState([]);
  const [saving, setSaving] = useState(false);
  const codes = useQuery(GET_ITEMCODES, { fetchPolicy: 'no-cache', variables: { constructionId: construction.id } });

  const [boqsByCode, setBoqsByCode] = useState({});
  const [selectedCode, setSelectedCode] = useState(null);
  const [newItem, setNewItem] = useState({});

  useEffect(() => {
    if (!isSubcontractor && codes.data && codes.data.constructionItemCodes) {
      // Create distinct list of itemCodes
      const itemCodesMap = {};
      const itemCodesArray = [];
      const newBoqsByCode = {};
      schedule.items.forEach((i) => {
        const boq = construction.boqs?.find((b) => b.id === i.boqId);
        if (boq) {
          itemCodesMap[boq.code] = true;
          newBoqsByCode[boq.code] = boq;
        }
      });
      codes.data.constructionItemCodes.forEach((cic) => {
        if (!itemCodesMap[cic.code]) {
          const boq = construction.boqs?.find((b) => b.code === cic.code);
          if (boq) {
            newBoqsByCode[boq.code] = boq;
          }

          itemCodesMap[cic.code] = true; // maintain distinct list
          itemCodesArray.push(cic);
        }
      });
      setItemCodes(itemCodesArray);
      setBoqsByCode(newBoqsByCode);
    } else if (
      isSubcontractor &&
      codes.data &&
      codes.data.constructionItemCodes &&
      childCodes?.subcontractorItemCodes
    ) {
      // Create distinct list of child Item codes for subcontractor based on selected BOQs

      const itemCodesMap = {};
      const itemChildCodesMap = {};
      const itemCodesArray = [];
      const newBoqsByCode = {};
      schedule.items.forEach((i) => {
        const boq = construction.boqs?.find((b) => b.id === i.boqId);
        if (boq) {
          itemCodesMap[boq.code] = true;
          newBoqsByCode[boq.code] = boq;

          if (i.childCode) {
            itemChildCodesMap[`${boq.code}-${i.childCode}`] = true;
          }
        }
      });
      childCodes.subcontractorItemCodes.forEach((cic) => {
        //console.log(cic);
        const key = `${cic.parentCode}-${cic.childCode}`;
        // if (itemCodesMap[cic.parentCode] && !itemChildCodesMap[key]) {
        if (!itemChildCodesMap[key]) {
          itemChildCodesMap[key] = true; // maintain distinct list
          itemCodesArray.push(cic);
        }
      });
      setItemCodes(itemCodesArray);
      setBoqsByCode(newBoqsByCode);
    }
  }, [isSubcontractor, childCodes, codes.data]);

  useEffect(() => {
    const options = itemCodes.map((x) => {
      return {
        value: x.id,
        label: `${x.itemType?.substring(0, 20)}: ${x.childCode ?? x.code}: ${x.description}`
      };
    });
    setItemCodeOptions(options);
  }, [itemCodes]);

  return (
    <Modal isOpen={true} onClose={onClose} size="lg">
      <ModalHeader>Add unplanned item</ModalHeader>
      <ModalBody>
        <AvForm>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label" htmlFor="itemCode">
                  Item Code
                </label>
                <Select
                  className="col-sm-9"
                  options={itemCodeOptions}
                  name="itemCode"
                  onChange={(e) => {
                    var i = itemCodes.find((i) => i.id == e.value);
                    setSelectedCode(i);
                    if (isSubcontractor) {
                      setNewItem({
                        ...newItem,
                        boq: boqsByCode[i.parentCode],
                        code: i.parentCode,
                        childCode: i.childCode,
                        description: i.description ?? '',
                        unitOfMeasure: i.unitOfMeasure ?? '',
                        itemType: i.itemType ?? ''
                      });
                    } else {
                      setNewItem({
                        ...newItem,
                        boq: boqsByCode[i.parentCode],
                        code: i.code,
                        description: i.description ?? '',
                        unitOfMeasure: i.unitOfMeasure ?? '',
                        itemType: i.itemType ?? ''
                      });
                    }
                  }}
                />
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Description</label>
                <div className="col-sm-9 p-2">{selectedCode?.description}</div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label" htmlFor="itemCode">
                  UOM
                </label>
                <div className="col-sm-9 p-2">{selectedCode?.unitOfMeasure}</div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label" htmlFor="variationQty">
                  Quantity *
                </label>
                <input
                  className="form-control col-sm-9"
                  type="number"
                  placeholder="Quantity"
                  name="variationQty"
                  rows="2"
                  defaultValue={newItem.variationQty}
                  onChange={(e) => setNewItem({ ...newItem, variationQty: parseFloat(e.target.value) })}
                />
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label" htmlFor="reason">
                  Reason *
                </label>
                <input
                  className="form-control col-sm-9"
                  type="text"
                  placeholder="Reason"
                  name="reason"
                  rows="2"
                  defaultValue={newItem.reason}
                  onChange={(e) => setNewItem({ ...newItem, reason: e.target.value })}
                />
              </div>
            </div>
          </div>
        </AvForm>
      </ModalBody>
      <ModalFooter>
        {(!selectedCode || !newItem.variationQty || !newItem.reason || newItem.reason === '') && (
          <span className="text-danger">Select an item and provide a quantity and reason</span>
        )}
        {!saving && (
          <Button disabled={saving} onClick={onClose}>
            Cancel
          </Button>
        )}
        {saving && <Spinner size="sm"></Spinner>}
        <Button
          color="primary"
          disabled={saving || !selectedCode || !newItem.variationQty || !newItem.reason || newItem.reason === ''}
          onClick={async () => {
            setSaving(true);
            try {
              await onAdd(newItem);
            } catch (error) {
              setSaving(false);
            }
          }}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const ChangeConstructionScheduleItem = ({ construction, item, boq, childCode, schedule, onChange, onClose }) => {
  const [saving, setSaving] = useState(false);

  const [changedValues, setChangedValues] = useState({ ...item });

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalHeader>Change item quantity</ModalHeader>
      <ModalBody>
        <AvForm>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group row">
                <label className="col-sm-5 col-form-label">
                  <strong>Item Code:</strong>
                </label>
                <div className="col-sm-7">{childCode?.childCode ?? boq?.code}</div>
              </div>
              <div className="form-group row">
                <label className="col-sm-5 col-form-label">
                  <strong>Description:</strong>
                </label>
                <div className="col-sm-7">{childCode?.description ?? boq?.description}</div>
              </div>
              <div className="form-group row">
                <label className="col-sm-5 col-form-label">
                  <strong>Planned Quantity:</strong>
                </label>
                <div className="col-sm-7">
                  {childCode ? item.quantity : boq?.variationQty ?? boq?.quantity} {boq?.unitOfMeasure}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-5 col-form-label" htmlFor="completedQty">
                  <strong>Completed Quantity *</strong>
                </label>
                <div className="col-sm-7">
                  <input
                    className="form-control"
                    type="number"
                    placeholder="completed quantity"
                    id="completedQty"
                    name="completedQty"
                    rows="2"
                    defaultValue={item.completedQty}
                    onChange={(e) => setChangedValues({ ...changedValues, completedQty: parseFloat(e.target.value) })}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-5 col-form-label" htmlFor="variationReason">
                  <strong>Reason for variation *</strong>
                </label>
                <div className="col-sm-7">
                  <textarea
                    className="form-control"
                    type="text"
                    placeholder="reason"
                    id="variationReason"
                    name="variationReason"
                    rows="2"
                    defaultValue={item.variationReason}
                    onChange={(e) => setChangedValues({ ...changedValues, variationReason: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </div>
        </AvForm>
      </ModalBody>
      <ModalFooter>
        {((!changedValues.completedQty && changedValues.completedQty !== 0) ||
          changedValues.completedQty < 0 ||
          !changedValues.variationReason ||
          changedValues.variationReason === '') && (
          <span className="text-danger">Provide a quantity and reason for variation</span>
        )}
        {!saving && (
          <Button disabled={saving} onClick={onClose}>
            Cancel
          </Button>
        )}
        {saving && <Spinner size="sm"></Spinner>}
        <Button
          color="primary"
          disabled={
            (!changedValues.completedQty && changedValues.completedQty !== 0) ||
            !changedValues.variationReason ||
            changedValues.variationReason === ''
          }
          onClick={async () => {
            setSaving(true);
            try {
              await onChange({ ...changedValues });
            } catch (error) {
              setSaving(false);
            }
          }}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const EditPhoto = ({ imageDetails, onImageEdit, onClose }) => {
  const auth = useAuth();
  const originalTitle = imageDetails?.title;
  const [editingTitle, setEditingTitle] = useState(null);
  const [comments, setComments] = useState(null);

  useEffect(() => {
    if (imageDetails) {
      const lastIndex = imageDetails.title.lastIndexOf('.');
      setEditingTitle(imageDetails.title.slice(0, lastIndex));
      setComments(imageDetails.comments);
    }
  }, [imageDetails]);

  const onSave = () => {
    const tags = imageDetails.tags?.split(';').filter((x) => x) || [];

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    let img = new Image();
    // Allow cors for S3 urls so toDataURL works
    img.crossOrigin = 'anonymous';
    img.src = imageDetails.url;

    img.onload = async function () {
      const portrait = img.width < img.height;
      context.canvas.height = portrait ? 700 : 480;
      context.canvas.width = portrait ? 480 : 700;
      context.drawImage(
        img,
        0,
        0,
        img.naturalWidth,
        img.naturalHeight,
        0,
        0,
        context.canvas.width,
        context.canvas.height
      );

      drawTitle(context, portrait, tags);
      const newSrc = canvas.toDataURL('image/png');
      const extension = imageDetails.title.split('.').pop();

      try {
        const params = await getPresignedUpdateParams({
          name: editingTitle + '.' + extension,
          tags: tags,
          comments: comments,
          id: imageDetails.id
        });

        const response =
          editingTitle + '.' + extension !== originalTitle ? await uploadAttachment(params.uploadUrl, newSrc) : null;
        onImageEdit({ ...imageDetails, title: editingTitle + '.' + extension, comments: comments, url: newSrc });
      } catch (err) {
        console.error(err);
        return;
      }
      onClose();
    };
  };

  const drawTitle = (context, portrait, tags) => {
    // update the file name
    let width = portrait ? 480 : 700;
    context.fillStyle = '#000000';
    context.fillRect(0, 0, width, 20);
    context.fillStyle = '#ffffff';
    context.font = '16px Arial';

    const text = `${editingTitle ?? ''} - [${tags?.join(', ').trim()}]`;
    context.fillText(text, 5, 16);
  };

  const getPresignedUpdateParams = async ({ name, size, tags, comments, id }) => {
    const res = await fetch('/api/attachments/getPresignedUpdateParams', {
      method: 'POST',
      body: JSON.stringify({
        name,
        attachmentId: id,
        tags: tags,
        comments: comments
      }),
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${auth.token}`
      }
    });
    return await res.json();
  };

  const uploadAttachment = (url, imageData) => {
    const imageBlob = dataURItoBlob(imageData);

    return fetch(url, {
      method: 'PUT',
      body: imageBlob,
      headers: { 'Content-Type': 'image/png' }
    });
  };

  function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  return (
    <Modal size="md" isOpen={true} onClose={onclose}>
      <ModalHeader>Edit Photo Details</ModalHeader>
      <ModalBody>
        <div className="form-group">
          <label className="form-label">Title</label>
          {
            <input
              name="title"
              defaultValue={editingTitle}
              onChange={(e) => setEditingTitle(e.target.value)}
              type="text"
              className="form-control"
            />
          }
        </div>
        <div className="form-group">
          <label className="form-label">Comments</label>
          <input
            name="comments"
            value={comments ?? ''}
            onChange={(e) => setComments(e.target.value)}
            type="text"
            className="form-control"
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Cancel</Button> <Button onClick={() => onSave()}>Save</Button>
      </ModalFooter>
    </Modal>
  );
};

const LazyImg = (props) => {
  const [inView, setInView] = useState(false);
  const placeholderRef = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver((entries, obs) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          setInView(true);
          obs.disconnect();
        }
      }
    }, {});
    observer.observe(placeholderRef.current);
    return () => {
      observer.disconnect();
    };
  }, []);
  return inView ? (
    <img {...props} alt={props.alt || ''} />
  ) : (
    <img {...props} ref={placeholderRef} src={placeholder} alt={props.alt || ''} />
  );
};

export default Construction;
