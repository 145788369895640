// Will update this to be the single source of static construction fields in the next phase
export const constructionFields = [
  {
    fieldName: 'id',
    displayName: 'Id'
  },
  {
    fieldName: 'status',
    displayName: 'Status'
  },
  {
    fieldName: 'comments',
    displayName: 'Comments'
  },
  {
    fieldName: 'workOrder',
    displayName: 'Work Order'
  },
  {
    fieldName: 'releaseDate',
    displayName: 'Job Received Date',
    isDate: true,
    bookingEditable: true
  },
  {
    fieldName: 'projectFSAM',
    displayName: 'Project FSAM'
  },
  {
    fieldName: 'description',
    displayName: 'Description',
    isOptionalFieldWorkTask: true
  },
  {
    fieldName: 'address',
    displayName: 'Address'
  },
  {
    fieldName: 'contactDetails',
    displayName: 'Contact Details'
  },
  {
    fieldName: 'buildType',
    displayName: 'Build Type',
    isOptionalFieldWorkTask: true,
    bookingEditable: true
  },
  {
    fieldName: 'workType',
    displayName: 'Work Type',
    isOptionalFieldWorkTask: true
  },
  {
    fieldName: 'premises',
    displayName: 'Premises'
  },
  {
    fieldName: 'deliveryPartner',
    displayName: 'Delivery Partner',
    isOptionalFieldWorkTask: true,
    bookingEditable: true
  },
  {
    fieldName: 'deliveryPartnerPm',
    displayName: 'DP PM',
    isOptionalFieldWorkTask: true
  },
  {
    fieldName: 'developer',
    displayName: 'Developer',
    isOptionalFieldWorkList: true,
    isOptionalFieldWorkTask: true
  },
  {
    fieldName: 'adtechPm',
    displayName: 'Internal PM',
    isUser: true
  },
  {
    fieldName: 'scheduler',
    displayName: 'Scheduler',
    isUser: true,
    isOptionalFieldWorkTask: true,
    bookingEditable: true
  },
  {
    fieldName: 'originalDueDate',
    displayName: 'Original Due Date',
    isDate: true,
    bookingEditable: true
  },
  {
    fieldName: 'updatedDueDate',
    displayName: 'Updated PC Due Date',
    isDate: true
  },
  {
    fieldName: 'dbydExpiryDate',
    displayName: 'DBYD Expiry',
    isDate: true,
    isOptionalFieldWorkTask: true
  },
  {
    fieldName: 'laseDate',
    displayName: 'LASE',
    isDate: true,
    isOptionalFieldWorkTask: true
  },
  {
    fieldName: 'startDueDate',
    displayName: 'Start Due Date',
    isDate: true
  },
  {
    fieldName: 'onsiteDate',
    displayName: 'Onsite Date',
    isDate: true
  },
  {
    fieldName: 'integrationDate',
    displayName: 'Integration Date',
    isDate: true,
    isOptionalFieldWorkTask: true
  },
  {
    fieldName: 'crqDate',
    displayName: 'CRQ Date',
    isDate: true,
    isOptionalFieldWorkTask: true
  },
  {
    fieldName: 'crqNumber',
    displayName: 'CRQ Number',
    isOptionalFieldWorkTask: true
  },
  {
    fieldName: 'acceleratedJob',
    displayName: 'Accelerated Job?',
    isBool: true,
    isOptionalFieldWorkTask: true,
    bookingEditable: true
  },
  {
    fieldName: 'siteContact',
    displayName: 'Site Contact Name'
  },
  {
    fieldName: 'siteContactPhone',
    displayName: 'Site Contact Phone',
    isOptionalFieldWorkTask: true
  },
  {
    fieldName: 'contract',
    displayName: 'Contract'
  },
  {
    fieldName: 'region',
    displayName: 'Region',
    isOptionalFieldWorkTask: true
  },
  {
    fieldName: 'quoteNumber',
    displayName: 'Quote Number'
  },
  {
    fieldName: 'prePaymentNumber',
    displayName: 'PrePayment Number'
  },
  {
    fieldName: 'bookedDate',
    displayName: 'Booked Date',
    isDate: true
  },
  {
    fieldName: 'workTasksCompleteDate',
    displayName: 'Work Tasks Complete Date',
    isDate: true
  },
  {
    fieldName: 'customerRef',
    displayName: 'Customer Ref',
    bookingEditable: true,
    isOptionalFieldWorkList: true,
    isOptionalFieldWorkTask: true
  },
  {
    fieldName: 'locationId',
    displayName: 'Location Id'
  },
  {
    fieldName: 'lotNumber',
    displayName: 'Lot Number'
  },
  {
    fieldName: 'unitNumber',
    displayName: 'Unit Number'
  },
  {
    fieldName: 'streetNumber',
    displayName: 'Street Number'
  },
  {
    fieldName: 'streetName',
    displayName: 'Street Name'
  },
  {
    fieldName: 'roadType',
    displayName: 'Road Type'
  },
  {
    fieldName: 'suburb',
    displayName: 'Suburb'
  },
  {
    fieldName: 'state',
    displayName: 'State'
  },
  {
    fieldName: 'postCode',
    displayName: 'Post Code'
  },
  {
    fieldName: 'isReceived',
    displayName: 'Is Received',
    isBool: true
  },
  {
    fieldName: 'statusChangeHangfireId',
    displayName: 'Status Change Hangfire Id'
  }
];

export const jobDelegations = [
  {
    fieldName: 'id',
    displayName: 'Id'
  },
  {
    fieldName: 'delegatedToId',
    displayName: 'Delegated To Id'
  },
  {
    fieldName: 'constructionId',
    displayName: 'Construction Id'
  },
  {
    fieldName: 'foreignId',
    displayName: 'Foreign Id'
  },
  {
    fieldName: 'returnUrl',
    displayName: 'Return Url'
  },
  {
    fieldName: 'returned',
    displayName: 'Returned',
    isBool: true
  },
  {
    fieldName: 'delegatorComments',
    displayName: 'Delegator Comments'
  },
  {
    fieldName: 'delegateeComments',
    displayName: 'Delegatee Comments'
  },
  // coming soon
  // {
  //   fieldName: 'acceptedDate',
  //   displayName: 'Accepted Date',
  //   isDate: true
  // },
  // {
  //   fieldName: 'rejectedDate',
  //   displayName: 'Rejected Date',
  //   isDate: true
  // },
  {
    fieldName: 'awaitingResubmission',
    displayName: 'Awaiting Resubmission',
    isBool: true
  },
  {
    fieldName: 'approvedDate',
    displayName: 'Approved Date',
    isDate: true
  },
  {
    fieldName: 'submittedDate',
    displayName: 'Submitted Date',
    isDate: true
  }
];

export const taskFields = [
  {
    fieldName: 'id',
    displayName: 'Id'
  },
  {
    fieldName: 'childTaskId',
    displayName: 'Child Task Id'
  },
  {
    fieldName: 'constructionId',
    displayName: 'Job Id'
  },
  {
    fieldName: 'name',
    displayName: 'Task Name'
  },
  {
    fieldName: 'owner',
    displayName: 'Owner',
    isUser: true,
    isOptionalFieldWorkList: true
  },
  {
    fieldName: 'groupId',
    displayName: 'Resource'
  },
  {
    fieldName: 'issuedDate',
    displayName: 'Issued Date',
    isDate: true,
    isOptionalFieldWorkList: true
  },
  {
    fieldName: 'dueDate',
    displayName: 'Due Date',
    isDate: true,
    isOptionalFieldWorkList: true,
    isOptionalFieldWorkTask: true
  },
  {
    fieldName: 'completedDate',
    displayName: 'Completed Date',
    isDate: true
  },
  {
    fieldName: 'submittedDate',
    displayName: 'Submitted Date',
    isDate: true
  },
  {
    fieldName: 'comments',
    displayName: 'Comments'
  },
  {
    fieldName: 'initial',
    displayName: 'Initial',
    isBool: true
  },
  {
    fieldName: 'ignored',
    displayName: 'Ignored',
    isBool: true
  },
  {
    fieldName: 'isReopened',
    displayName: 'Is Reopened',
    isBool: true
  },
  {
    fieldName: 'onsiteDate',
    displayName: 'Onsite Date',
    isDate: true,
    isOptionalFieldWorkList: true,
    isOptionalFieldWorkTask: true
  },
  {
    fieldName: 'reminderDate',
    displayName: 'Reminder Date',
    isDate: true
  },
  {
    fieldName: 'reminderComments',
    displayName: 'Reminder Comments'
  },
  {
    fieldName: 'acceptedDate',
    displayName: 'Accepted Date',
    isDate: true
  },
  {
    fieldName: 'declinedDate',
    displayName: 'Declined Date',
    isDate: true
  },
  {
    fieldName: 'scheduledStartTime',
    displayName: 'Scheduled Start Time',
    isDateTime: true,
    isOptionalFieldWorkList: true
  },
  {
    fieldName: 'scheduledEndTime',
    displayName: 'Scheduled End Time',
    isDateTime: true
  }
];

export const boqFields = [
  {
    fieldName: 'id',
    displayName: 'Id'
  },
  {
    fieldName: 'constructionId',
    displayName: 'Construction Id'
  },
  {
    fieldName: 'code',
    displayName: 'Code'
  },
  {
    fieldName: 'designVariation',
    displayName: 'Design Variation'
  },
  {
    fieldName: 'description',
    displayName: 'Description'
  },
  {
    fieldName: 'itemType',
    displayName: 'Item Type'
  },
  {
    fieldName: 'unitAmount',
    displayName: 'Unit Amount'
  },
  {
    fieldName: 'unitOfMeasure',
    displayName: 'Unit Of Measure'
  },
  {
    fieldName: 'quantity',
    displayName: 'Quantity'
  },
  {
    fieldName: 'variationQty',
    displayName: 'Variation Qty'
  },
  {
    fieldName: 'assignedQty',
    displayName: 'Assigned Qty'
  },
  {
    fieldName: 'completedQty',
    displayName: 'Completed Qty'
  },
  {
    fieldName: 'estimatedTime',
    displayName: 'Estimated Time'
  },
  {
    fieldName: 'comments',
    displayName: 'Comments'
  }
];

export const bomFields = [
  {
    fieldName: 'id',
    displayName: 'Id'
  },
  {
    fieldName: 'constructionId',
    displayName: 'Construction Id'
  },
  {
    fieldName: 'code',
    displayName: 'Code'
  },
  {
    fieldName: 'description',
    displayName: 'Description'
  },
  {
    fieldName: 'unitOfMeasure',
    displayName: 'Unit Of Measure'
  },
  {
    fieldName: 'quantity',
    displayName: 'Quantity'
  },
  {
    fieldName: 'identifier',
    displayName: 'Identifier'
  },
  {
    fieldName: 'orderedQuantity',
    displayName: 'Ordered Quantity'
  },
  {
    fieldName: 'receivedQuantity',
    displayName: 'Received Quantity'
  },
  {
    fieldName: 'dueDate',
    displayName: 'Due Date',
    isDate: true
  },
  {
    fieldName: 'receivedDate',
    displayName: 'Received Date',
    isDate: true
  }
];

export const claimFields = [
  {
    fieldName: 'id',
    displayName: 'Id'
  },
  {
    fieldName: 'constructionId',
    displayName: 'Construction Id'
  },
  {
    fieldName: 'code',
    displayName: 'Code'
  },
  {
    fieldName: 'description',
    displayName: 'Description'
  },
  {
    fieldName: 'unitOfMeasure',
    displayName: 'Unit Of Measure'
  },
  {
    fieldName: 'quantity',
    displayName: 'Quantity'
  },
  {
    fieldName: 'claimQuantity',
    displayName: 'Claim Quantity'
  },
  {
    fieldName: 'rate',
    displayName: 'Rate'
  },
  {
    fieldName: 'totalExGst',
    displayName: 'Total Ex-Gst'
  },
  {
    fieldName: 'total',
    displayName: 'Total'
  },
  {
    fieldName: 'comments',
    displayName: 'Comments'
  }
];

export const expenseFields = [
  {
    fieldName: 'id',
    displayName: 'Id'
  },
  {
    fieldName: 'name',
    displayName: 'Name'
  },
  {
    fieldName: 'scheduleId',
    displayName: 'Schedule Id'
  },
  {
    fieldName: 'constructionId',
    displayName: 'Construction Id'
  },
  {
    fieldName: 'owner',
    displayName: 'Owner'
  },
  {
    fieldName: 'comments',
    displayName: 'Comments'
  },
  {
    fieldName: 'description',
    displayName: 'Description'
  },
  {
    fieldName: 'domainName',
    displayName: 'Domain Name'
  },
  {
    fieldName: 'invoiceNo',
    displayName: 'Invoice No'
  },
  {
    fieldName: 'valueExGst',
    displayName: 'Value ExGst'
  },
  {
    fieldName: 'valueIncGst',
    displayName: 'Value IncGst'
  },
  {
    fieldName: 'xeroBillNo',
    displayName: 'Xero Bill No'
  },
  {
    fieldName: 'xeroPoNo',
    displayName: 'Xero Po No'
  },
  {
    fieldName: 'paymentDueDate',
    displayName: 'Payment Due Date',
    isDate: true
  },
  {
    fieldName: 'paidAmountExGst',
    displayName: 'Paid Amount ExGst'
  },
  {
    fieldName: 'paidAmountIncGst',
    displayName: 'Paid Amount IncGst'
  },
  {
    fieldName: 'paidDate',
    displayName: 'Paid Date',
    isDate: true
  },
  {
    fieldName: 'invoiceDate',
    displayName: 'Invoice Date',
    isDate: true
  }
];

export const paymentItemFields = [
  {
    fieldName: 'id',
    displayName: 'Id'
  },
  {
    fieldName: 'paymentId',
    displayName: 'Payment Id'
  },
  {
    fieldName: 'boqId',
    displayName: 'Boq Id'
  },
  {
    fieldName: 'itemCode',
    displayName: 'Item Code'
  },
  {
    fieldName: 'originalQty',
    displayName: 'Original Qty'
  },
  {
    fieldName: 'completedQty',
    displayName: 'Completed Qty'
  },
  {
    fieldName: 'unitAmount',
    displayName: 'Unit Amount'
  },
  {
    fieldName: 'valueExGst',
    displayName: 'Value ExGst'
  },
  {
    fieldName: 'valueIncGst',
    displayName: 'Value IncGst'
  },
  {
    fieldName: 'rejectedDate',
    displayName: 'Rejected Date',
    isDate: true
  },
  {
    fieldName: 'approvedDate',
    displayName: 'Approved Date',
    isDate: true
  },
  {
    fieldName: 'approvedBy',
    displayName: 'Approved By',
    isUser: true
  },
  {
    fieldName: 'comments',
    displayName: 'Comments'
  },
  {
    fieldName: 'variationReason',
    displayName: 'Variation Reason'
  }
];

export const invoiceFields = [
  {
    fieldName: 'id',
    displayName: 'Id'
  },
  {
    fieldName: 'constructionId',
    displayName: 'Construction Id'
  },
  {
    fieldName: 'comments',
    displayName: 'Comments'
  },
  {
    fieldName: 'amountClaimedExGst',
    displayName: 'Amount Claimed ExGst'
  },
  {
    fieldName: 'amountClaimedIncGst',
    displayName: 'Amount Claimed IncGst'
  },
  {
    fieldName: 'rctiNo',
    displayName: 'Rcti No'
  },
  {
    fieldName: 'rctiValueExGst',
    displayName: 'Rcti Value ExGst'
  },
  {
    fieldName: 'rctiValueIncGst',
    displayName: 'Rcti Value IncGst'
  },
  {
    fieldName: 'retentionHeldExGst',
    displayName: 'Retention Held ExGst'
  },
  {
    fieldName: 'retentionHeldIncGst',
    displayName: 'Retention Held IncGst'
  },
  {
    fieldName: 'prePaymentExGst',
    displayName: 'PrePayment ExGst'
  },
  {
    fieldName: 'prePaymentIncGst',
    displayName: 'PrePayment IncGst'
  },
  {
    fieldName: 'xeroInvoiceDate',
    displayName: 'Xero Invoice Date',
    isDate: true
  },
  {
    fieldName: 'xeroInvoiceNo',
    displayName: 'Xero Invoice No'
  },
  {
    fieldName: 'xeroStatus',
    displayName: 'Xero Status'
  },
  {
    fieldName: 'paymentDueDate',
    displayName: 'Payment Due Date',
    isDate: true
  },
  {
    fieldName: 'paidAmountExGst',
    displayName: 'Paid Amount ExGst'
  },
  {
    fieldName: 'paidAmountIncGst',
    displayName: 'Paid Amount IncGst'
  },
  {
    fieldName: 'paidDate',
    displayName: 'Paid Date',
    isDate: true
  }
];

export const commentFields = [
  {
    fieldName: 'id',
    displayName: 'Id'
  },
  {
    fieldName: 'constructionId',
    displayName: 'Construction Id'
  },
  {
    fieldName: 'comment',
    displayName: 'Comment'
  }
];

export const taskUserFields = [
  {
    fieldName: 'id',
    displayName: 'Id'
  },
  {
    fieldName: 'userId',
    displayName: 'User',
    isUser: true
  },
  {
    fieldName: 'scheduleId',
    displayName: 'Task Id'
  },
  {
    fieldName: 'onSiteUtc',
    displayName: 'On-site Time',
    isDateTime: true
  },
  {
    fieldName: 'offSiteUtc',
    displayName: 'Off-site Time',
    isDateTime: true
  },
  {
    fieldName: 'completedUtc',
    displayName: 'Completed Date',
    isDate: true
  },
  {
    fieldName: 'confirmItemQtys',
    displayName: 'Confirm Item Qtys'
  }
];

export const groupFields = [
  {
    fieldName: 'id',
    displayName: 'Id'
  },
  {
    fieldName: 'name',
    displayName: 'Name'
  },
  {
    fieldName: 'isSubcontractor',
    displayName: 'Is Subcontractor',
    isBool: true
  },
  {
    fieldName: 'canSchedule',
    displayName: 'Can Schedule',
    isBool: true
  },
  {
    fieldName: 'scheduleOrder',
    displayName: 'Schedule Order'
  },
  {
    fieldName: 'tasks',
    displayName: 'Tasks'
  },
  {
    fieldName: 'isActive',
    displayName: 'Is Active',
    isBool: true
  }
];

export const getFields = (table) => {
  if (table === 'Constructions') return constructionFields;
  if (table === 'JobDelegations') return jobDelegations;
  if (table === 'Tasks') return taskFields;
  if (table === 'Boqs') return boqFields;
  if (table === 'Boms') return bomFields;
  if (table === 'Claims') return claimFields;
  if (table === 'Expenses') return expenseFields;
  if (table === 'PaymentItems') return paymentItemFields;
  if (table === 'Invoices') return invoiceFields;
  if (table === 'Comments') return commentFields;
  if (table === 'TaskUsers') return taskUserFields;
  if (table === 'Groups') return groupFields;
  return [];
};
